import React from "react"
import { Link } from "gatsby"

// import "./mystyles.sass"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import PageHeaderQuote from '../components/PageHeaderQuote'

import heroImage from '../images/content/soo_landing_03.jpg'
import portraits from '../images/content/projects/2019-20-feeding-program/portraits.jpg'
import imageFunRun1 from '../images/content/projects/2018-19-feeding-program/2019-FunRun.png'
import alao01 from '../images/content/projects/2019-20-feeding-program/fp-alao-dc-108.jpg'
import alao02 from '../images/content/projects/2019-20-feeding-program/fp-alao-dc-120.jpg'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <section className="hero is-link is-fullheight-with-navbar has-background-white" style={{
                backgroundImage: `url(${
                  alao02
                })`,
                marginBottom: 0,
                backgroundPosition: 'center',
                backgroundSize: 'cover'
              }}>
      <div className="hero-body">
        <div className="container">
          <h1 className="title is-uppercase" style={{
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                color: '#A13130',
                padding: '1rem',
                marginTop: '20rem'
              }}>
            Every child deserves an education
          </h1>
        </div>
      </div>
    </section>


    <PageHeaderQuote quote="IF WE HAVE NO FAITH IN THE FUTURE,
                            THEN WE HAVE NO POWER IN THE PRESENT"
                      author="Unknown" />
    {/* <div className="container">
      <div className="section">
        <h2 className="title is-2 has-text-grey-dark has-text-weight-light has-text-centered">
          IF WE HAVE NO FAITH IN THE FUTURE, <br/>
          THEN WE HAVE NO POWER IN THE PRESENT
        </h2>
        <br/>
      </div>
    </div> */}
    <div className="section has-background-white-ter">
      <div className="container">
        <p className="has-text-centereda is-size-4 has-text-grey-dark">
        The Sum of One is more than just an organization. We have a very real passion that moves us to seeing education expanded, poverty extinguished, and communities developed for sustainable futures. We believe that we can do more than just imagine change, we can be a part of the change.  Join us in our fight to end poverty and make an investment by making education possible for impoverished students.
        </p>
      </div>
    </div>
    
    {/* Fun Run Promo */}
    <div className="section  has-background-white-ter-">
      <div className="container">
        <div className="columns is-multiline ">
          <div className="column is-half">
            <img src={imageFunRun1} alt="2018 Fun Run" />
          </div>
          
          <div className="column is-half">
            <h3 className="title is-3 has-text-grey-darker is-uppercase">Fun Run</h3>
            <div className="separator"></div>
            <br/>
            <p>
              To help fund and raise awareness for the <Link to="/projects/2019-20-feeding-program" id="Home-201920-FeedingProgram">2019/2020 feeding program</Link>, we are hosting an amazing event on <strong>November 2, 2019</strong> and we would love for you to be a part of it. 
              <br/><br/>
              Bring out the whole family and support us at our <strong><a href="https://funrun.sumofone.org" id="Home-FunRun-Web-Button">second annual 5K Fun Run!</a></strong> 
              <br /><br />
              <a href="https://sumofone-funrun.eventbrite.com" className="button is-primary is-large" id="Home-FunRun-Register-Button">Register Today!</a>
              {/* For more information on the 
              Fun Run and how you can be a part, please visit us here. */}
            </p>
          </div>

        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
